import React from 'react'

import {About, Footer, Header, Skills, Testimonial, Work} from './container'
//Übersichetlichere Version um alle Komponenten zu importieren von container durch index.js
import { Navbar } from './components';
import './App.scss';

const App = () => {
  return (
    <div className='app'>
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      <Testimonial />
      <Footer />    
    </div>
  );
}

export default App;
