import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap } from '../../wrapper';
import './About.scss'
import {urlFor, client } from '../../client';

//Alte statischen Daten für die Seite, die durch die Datenbank von Sanity ersetzt wurden
/* const abouts = [
  {title: 'Frontend Developer', description: 'I am a good frontend developer', imgUrl: images.about01},
  {title: 'Backend Developer', description: 'I am a good backend developer', imgUrl: images.about02},
  {title: 'UX/UI Designer', description: 'I am a good UX/UI designer', imgUrl: images.about03},
] */

const About = () => {

  const [abouts, setAbouts]= useState([]);
  
  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {setAbouts(data);
    });
  }, []);

  return (
    <>
      <h2 className='head-text'> Sauberer  <span>Code</span>,  <br /> nachhaltiges <span>Wachstum</span>.
      </h2>

      <div className='app__profiles'>
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{opacity: 1}}
            whileHover={{scale: 1.1}}
            transition={{duration: 0.5, type: 'tween'}}
            className='app__profile-item'
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className='bold-text' style={{marginTop: 20}}>{about.title}</h2>
            <p className='p-text' style={{marginTop: 10}}>{about.description}</p>
          </motion.div>
        ))}

      </div>


    </>
  )
}

export default AppWrap(About, 'about', 'app__whitebg') //AppWrap als HOC für die About-Komponente und die ID 'about' als Parameter
 